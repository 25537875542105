#pageArtigosLista .container {
  padding: 5rem 2rem;
}

#pageArtigosLista .container h1,
#pageArtigosLista .container h2,
#pageArtigosLista .container h3 {
  margin-top: 0;
  color: var(--primary-color-blue);
}

/* --- Info --- */

#pageArtigosLista .wrapperInfo .container {
  padding-bottom: 0;
}

#pageArtigosLista .wrapperInfo .container h1 {
  font-size: var(--h3-font-size);
}

#pageArtigosLista .wrapperInfo .container .info p:last-of-type {
  margin-bottom: 0;
}

/* --- Lista --- */

#pageArtigosLista .wrapperList .container {
  display: flex;
  align-items: stretch;
  flex-flow: wrap;
  justify-content: space-between;
  gap: 2rem;
  padding-top: 2rem;
}

#pageArtigosLista .wrapperList .container .listItem {
  /* width: 40%; */
}

/* --- Mobile --- */

@media screen and (max-width: 768px) {
  #pageArtigosLista .container {  }

  /* --- Lista --- */
  
  #pageArtigosLista .wrapperList .container {
    gap: 1rem;
  }
}