#pageVisualizacoesLista article.listItem {
  flex: 20%;
  min-width: 150px;
}

#pageVisualizacoesLista article.listItem > a {
  display: block;
  text-decoration: none;
  border-top: 4px solid transparent;
  transition: all 300ms;
}

#pageVisualizacoesLista article.listItem figure {
  margin: 0;
}

#pageVisualizacoesLista article.listItem figure img {
  width: 100%;
}

#pageVisualizacoesLista article.listItem p {
  font-size: var(--p-font-size);
  color: var(--color-black-light);
}

#pageVisualizacoesLista article.listItem h2 {
  margin-top: 0.2rem;
  font-size: var(--h4-font-size);
  color: var(--primary-color-blue);
  text-align: center;
  transition: all 300ms;
}

/* --- MouseOver --- */

#pageVisualizacoesLista article.listItem > a:hover {
  transform: scale(1.05);
  border-top-color: var(--primary-color-yellow);
}

#pageVisualizacoesLista article.listItem > a:hover h2 {
  text-shadow: 0 0 1px var(--primary-color-blue);
}

/* --- Mobile --- */

@media screen and (max-width: 768px) {
  #pageVisualizacoesLista article.listItem { }
}