#pageInicial .container .LinkButton {
  margin-top: 0.5rem;
}

/* --- Banner --- */

#pageInicial .banner .banner-bg-image {
  background-image: url(./images/banner-bg.svg);
  background-position: calc(50% + 100px) center;
  background-size: auto 75%;
  background-repeat: no-repeat;
}

#pageInicial .banner .container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 5rem 2rem;
}

#pageInicial .banner .container .info > * {
  width: 60%;
  color: #fff;
  font-size: var(--p-font-size);
  font-weight: 300;
}

#pageInicial .banner .container .info > :first-child {
  margin-top: 0;
  font-size: var(--h2-font-size);
  font-weight: 400;
}

/* --- Highlight --- */

#pageInicial .highlight .container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2rem;
  padding: 5rem 2rem;
}

#pageInicial .highlight.gray .container {
  border-radius: 0.5rem;
  background-color: #f7f7f7;
}

#pageInicial .highlight .container .info {
  flex: 1;
}

#pageInicial .highlight .container .info h4,
#pageInicial .highlight .container .info h3 {
  margin: 0;
  color: var(--primary-color-blue);
}

#pageInicial .highlight .container .image {
  flex: 1;
}

#pageInicial .highlight .container .image img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

/* --- Visualizations --- */

#pageInicial .visualizations .container {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  padding: 5rem 2rem;
}

#pageInicial .visualizations .container h4 {
  color: var(--primary-color-blue);
}

#pageInicial .visualizations .container .items {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 4rem;
  margin: 1rem 0 3rem;
}

#pageInicial .visualizations .container .items article {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  width: 33%;
  text-align: left;
}

#pageInicial .visualizations .container .items article img {
  margin-bottom: 0.5rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.3rem;
}

#pageInicial .visualizations .container .items article a {
  color: inherit;
  text-decoration: none;
}

#pageInicial .visualizations .container .items article h3 {
  margin: 8px 0 15px;
  font-weight: 500;
  font-size: var(--h5-font-size);
  color: var(--primary-color-blue);
}

/* --- Quality --- */

#pageInicial .quality {
  color: #fff;
  text-align: center;
}

#pageInicial .quality .quality-bg {
  background-image: url(./images/quality-bg.png);
  background-size: cover;
  background-position: center center;
}

#pageInicial .quality .container {
  padding: 8rem 10vw;
}

#pageInicial .quality .container p {
  font-size: var(--h5-font-size);
  text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
}

#pageInicial .quality .container a {
  color: var(--color-blue-light);
  text-decoration: none;
}

/* --- Publications --- */

#pageInicial .publications .container {
  gap: 2rem;
  padding: 5rem 2rem;
}

#pageInicial .publications .container h4 {
  margin-top: 0;
  color: var(--primary-color-blue);
}

#pageInicial .publications .container .items {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 2rem;
  margin-bottom: 2rem;
}

#pageInicial .publications .container .items article {
  flex: 1px;
}

#pageInicial .publications .container .items article a {
  color: inherit;
  text-decoration: none;
}

#pageInicial .publications .container .items article a h2 {
  font-size: var(--h5-font-size);
  color: var(--primary-color-blue);
}

/* --- Mobile --- */

@media screen and (max-width: 768px) {
  #pageInicial .banner .banner-bg-image {
    background: none;
  }

  #pageInicial .banner .container .info > * {
    width: initial;
  }
  
  #pageInicial .banner .container .info {
    width: 100%;
  }
  
  #pageInicial .highlight .container {
    flex-flow: column nowrap;
    gap: 2rem;
  }

  #pageInicial .visualizations .container .items {
    overflow-x: auto;
    padding-bottom: 1rem;
    width: calc(100% + 2rem);
  }

  #pageInicial .visualizations .container .items article {
    min-width: 200px;
  }

  #pageInicial .publications .container .items {
    flex-flow: column;
  }

  #pageInicial .visualizations .container .items article span {
    display: none;
  }
}