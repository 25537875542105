#footer {
  padding: 5rem 0 0;
  background-color: #F3F3F3;
}

#footer .container {
  padding: 0;
}

#footer section {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0 0 5rem;
}

#footer section .info span {
  display: inline-block;
  flex: 3;
  margin-right: 0.5rem;
  width: 30vw;
  max-width: 200px;
  text-align: right;
  font-size: var(--p-font-size);
  color: var(--color-black-lighter);
  border-bottom: 1px solid var(--color-black-lighter);
}

#footer section .info .items {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex: 7;
}

#footer section img {
  /* opacity: 0.5; */
  margin: 0 1rem 1rem;
  max-width: 10rem;
  max-height: 4.5rem;
  /* filter: grayscale(100%); */
  transition: all 0.3s ease-in-out;
}

#footer section img.logoSus {
  max-width: 22rem;
  max-height: 8rem;
}

#footer section img:hover {
  opacity: 1;
  filter: grayscale(0);
}

/* --- Mobile --- */

@media screen and (max-width: 768px) {
  #footer section {
    flex-direction: column;
  }
  #footer section .info span {
    width: 50vw;
  }
  
  #footer section .items {
    padding: 2rem;
  }

  #footer section .items img {
    margin: 0 1rem 1.5rem;
  }

  #footer section .items img.logoSus {
    max-width: 100%;
  }
}
