$h1-font-size: 2.0rem;
$h2-font-size: 2.0rem;
$h3-font-size: 2.0rem;
$h4-font-size: 1.6rem;
$h5-font-size: 1.4rem;
$p-font-size: 1.1rem;
$small-font-size: 0.95rem;

:root {
  --h1-font-size: #{$h1-font-size};
  --h2-font-size: #{$h2-font-size};
  --h3-font-size: #{$h3-font-size};
  --h4-font-size: #{$h4-font-size};
  --h5-font-size: #{$h5-font-size};
  --p-font-size: #{$p-font-size};
  --small-font-size: #{$small-font-size};

  h1 {
    font-size: $h1-font-size;
    font-weight: 700;
  }
  
  h2 {
    font-size: $h2-font-size;
    font-weight: 400;
  }
  
  h3 {
    font-size: $h3-font-size;
    font-weight: 300;
  }
  
  h4 {
    font-size: $h4-font-size;
    font-weight: 500;
  }
  
  h5 {
    font-size: $h5-font-size;
    font-weight: 300;
  }
  
  section,
  div,
  li,
  a,
  button,
  span,
  p {
    font-size: $p-font-size;
    font-weight: 400;
  }
  
  small {
    font-size: $small-font-size;
    font-weight: 300;
  }
  
  strong {
    font-weight: 500;
  }
  
  li, p {
    line-height: 140%;
  }
  
  a {
    // font-size: inherit;
    color: var(--primary-color-blue);
  }
  
  // Mobile first
  html {
    font-size: 3.5vmin;
  }
}

// Medium screens
@media (min-width: 768px) {

  // @media (min-width: 768px), (min-height: 400px) {
  html {
    font-size: 1rem;
  }
}

// Large screens
@media (min-width: 1600px) {

  // @media (min-width: 1400px), (min-height: 800px) {
  html {
    font-size: 1.1rem;
  }
}

// TODO: add vars to root for below refs
//  title, subtitle, pargraph, small,
//  light, regular, bold.
