#pageBoletinsDetalhes .container {
  padding: 5rem 2rem;
}

#pageBoletinsDetalhes .container.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
}

/* --- Header --- */

#pageBoletinsDetalhes .wrapperHeader .container {
  padding-bottom: 1rem;
}

#pageBoletinsDetalhes .wrapperHeader .container h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: var(--h3-font-size);
  color: var(--primary-color-blue);
}

#pageBoletinsDetalhes .wrapperHeader .container p {
  margin-top: 15px;
  color: var(--color-black-lighter);
}

/* --- Boletim --- */

#pageBoletinsDetalhes .wrapperBoletim .container {
  padding-top: 0;
}

#pageBoletinsDetalhes .wrapperBoletim .container .boletim {
  padding-bottom: 4rem;
}

#pageBoletinsDetalhes .wrapperBoletim .container .boletim p {
  text-indent: 3rem;
  text-align: justify;
}
#pageBoletinsDetalhes .wrapperBoletim .container .boletim code {
  font-size: 90%;
  color: var(--color-red-dark);
}

#pageBoletinsDetalhes .wrapperBoletim .container .boletim figure {
  text-align: center;
}

#pageBoletinsDetalhes .wrapperBoletim .container .boletim figure img {
  width: 100%;
  max-width: 800px;
}

#pageBoletinsDetalhes .wrapperBoletim .container .boletim .MuiCircularProgress-root {
  position: absolute;
  z-index: 0;
  top: 35%;
  left: 50%;
}

/* --- Mobile --- */

@media screen and (max-width: 768px) {
  
  #pageBoletinsDetalhes .wrapperBoletim .container { }

}