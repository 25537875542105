#pageContato .container {
  padding: 5rem 2rem;
}

#pageContato .container h1,
#pageContato .container h2,
#pageContato .container h3 {
  margin-top: 0;
  color: var(--primary-color-blue);
}

#pageContato .info p {
  text-align: justify;
}

/* --- Conteudo --- */

#pageContato .conteudo .container h3 {
  margin-bottom: 0;
}

#pageContato .conteudo .container h2 {
  margin-bottom: 1rem;
  font-size: var(--h4-font-size);
}

#pageContato .conteudo .container address {
  font-style: normal;
}

#pageContato .conteudo .container #frameMapa {
  margin-top: 2.5rem;
}

/* --- Mobile --- */

@media screen and (max-width: 768px) {
  #pageContato .equipe .container .conteudo { }
}