#header {
  display: flex;
  flex-direction: column;
  margin: 0;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
}

#header > .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

#header > .container .projectBrand {
  width: 12rem;
}

#header > .container .projectBrand img {
  width: 100%;
  height: auto;
}

#header > .container .projectBrand h1 {
  margin: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

#header > .container .projectSupport {
  width: 9rem;
}

#header > .container .projectSupport img.defaultLogoLaddem {
  width: 100%;
  height: auto;
}

#header > .container .projectSupport img.mobileLogoLaddem {
  display: none;
}

#header > .container .mobileMenu {
  display: none;
}

#header > .container .mobileMenu .MuiSvgIcon-root {
  color: var(--primary-color-black);
}

/* --- Mobile --- */

@media screen and (max-width: 1000px){

  #header > .container {
    display: flex;
    flex-direction: row;
  }

  #header > .container .mobileMenu {
    display: block;
    flex:1;
    text-align: right;
  }

  #header > .container .projectSupport {
    padding-left: 1.5rem;
    margin-left: 1.5rem;
    border-left: 1px solid  var(--color-black-lighter);
  }

  #header > .container .projectSupport img.defaultLogoLaddem {
    display: none;
  }

  #header > .container .projectSupport img.mobileLogoLaddem {
    display: block;
    width: 40%;
    height: auto;
  }
}