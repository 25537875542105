#pageArtigosLista article.listItem {
  flex: 40%;
  min-width: 250px;
  box-sizing: border-box;
}

#pageArtigosLista article.listItem > a {
  display: block;
  position: relative;
  padding: 2rem;
  height: 100%;
  text-decoration: none;
  background-color: #f5f5f5;
  border: 1px solid #eee;
  border-top: 4px solid transparent;
  border-radius: 5px;
  transition: all 300ms;
}

#pageArtigosLista article.listItem h2 {
  font-size: var(--h4-font-size);
  color: var(--primary-color-blue);
  transition: all 300ms;
}

#pageArtigosLista article.listItem h2::before {
  content: "";
  position: absolute;
  left: 0;
  width: 3px;
  height: 30px;
  background-color: var(--primary-color-blue);
}

#pageArtigosLista article.listItem p.date {
  color: var(--color-black-lighter);
}

#pageArtigosLista article.listItem p {
  margin-bottom: 0.2rem;
  font-size: var(--p-font-size);
  color: var(--color-black-light);
}

#pageArtigosLista p.short{
  padding-bottom: 5rem;
}

#pageArtigosLista div.buttonsContainer {
  position: absolute;
  margin-right: auto;
  margin-left: auto;
  bottom: 1rem;
}

#pageArtigosLista Button {
  
}

/* --- MouseOver --- */

#pageArtigosLista article.listItem > a:hover {
  background-color: #fff;
  border-top-color: var(--primary-color-yellow);
  box-shadow: 0 0 15px #eee;
  transform: scale(1.05);
}

#pageArtigosLista article.listItem > a:hover h2 {
  text-shadow: 0 0 1px var(--primary-color-blue);
}

/* --- Mobile --- */

@media screen and (max-width: 768px) {
  #pageArtigosLista article.listItem { }
}