#pageCursosLista .container {
  padding: 5rem 2rem;
}

#pageCursosLista .container h1,
#pageCursosLista .container h2,
#pageCursosLista .container h3 {
  margin-top: 0;
  color: var(--primary-color-blue);
}

/* --- Info --- */

#pageCursosLista .wrapperInfo .container {
  padding-bottom: 0;
}

#pageCursosLista .wrapperInfo .container h1 {
  font-size: var(--h3-font-size);
}

#pageCursosLista .wrapperInfo .container .info p:last-of-type {
  margin-bottom: 0;
}

/* --- Lista --- */

#pageCursosLista .wrapperList .container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* align-content: flex-end; */
  /* flex-flow: wrap; */
  /* justify-content: space-between; */
  gap: 2rem;
  padding-top: 2rem;
}

/* --- Mobile --- */

@media screen and (max-width: 768px) {
  #pageCursosLista .container {  }

  /* --- Lista --- */
  
  #pageCursosLista .wrapperList .container {
    gap: 1rem;
  }
}