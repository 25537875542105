.LinkButton {
  display: inline-block;
  padding: 0.75rem 3rem;
  margin: 0 0.2rem;
  font-weight: 500;
  text-decoration: none;
  border-radius: 0.2rem;
  transition: all 300ms;
}

/* Contained */

.LinkButton.contained {
  color: #fff;
  background-color: var(--primary-color-blue);
  border: 0;
  transition: all 300ms;
}

.LinkButton.contained:hover {
  background-color: var(--color-blue-dark);
}

/* Outlined */

.LinkButton.outlined {
  color: var(--primary-color-blue);
  background-color: transparent;
  border: 1px solid var(--color-blue-light);
}

.LinkButton.outlined:hover {
  border-color: var(--primary-color-blue);
}

.LinkButton.outlined.white {
  border-color: #fff;
  color: #fff;
}

/* None */

.LinkButton.none {
  padding: initial;
}

/* Icon */

.LinkButton svg {
  margin-right: 0.5rem;
  font-size: inherit;
  vertical-align: middle;
}
.LinkButton span {
  vertical-align: middle;
}