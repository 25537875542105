#pageClassificacao .container {
  padding: 5rem 2rem;
}

#pageClassificacao .container h1 {
  margin-top: 0;
  font-size: var(--h3-font-size);
  color: var(--primary-color-blue);
}

#pageClassificacao .container p {
  text-align: justify;
}

/* --- Info --- */

#pageClassificacao .wrapperInfo {
  background-color: #f3f3f3;
}

#pageClassificacao .wrapperInfo .container {
  padding-bottom: 8rem;
}

#pageClassificacao .wrapperInfo .container a {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: var(--p-font-size);
  color: var(--primary-color-blue);
  text-decoration: none;
}

#pageClassificacao .wrapperInfo .container .info p:last-of-type {
  margin-bottom: 0;
}

/* --- Form --- */

#pageClassificacao .wrapperForm .container {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  top: -6rem;
}

#pageClassificacao .wrapperForm .container .form {
  position: relative;
  padding: 2rem;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #fff;
}

#pageClassificacao .wrapperForm .container .form .stepLabel h4 {
  opacity: 0.5;
}

#pageClassificacao .wrapperForm .container .form .stepLabel svg {
  width: 40px;
  height: 50px;
}

#pageClassificacao .wrapperForm .container .form .stepLabel .Mui-completed h4,
#pageClassificacao .wrapperForm .container .form .stepLabel .Mui-active h4 {
  opacity: 0.9;
}

#pageClassificacao .wrapperForm .container .form  .Mui-completed .stepLabel svg {
  fill: #6fd56f;
}

#pageClassificacao .wrapperForm .container .form label.MuiInputLabel-root {
  font-size: 1.2rem;
}

#pageClassificacao .wrapperForm .container .form fieldset.MuiOutlinedInput-notchedOutline legend span {
  font-size: 1.1em;
}

#pageClassificacao .wrapperForm .container .form .loading {
  /* position: absolute;
  z-index: 0;
  top: 35%;
  left: 50%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

#pageClassificacao .wrapperForm .container .form .MuiStepContent-root {
  padding-left: 2rem;
}

#pageClassificacao .wrapperForm .container .form .formElements {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 2.5rem;
}

#pageClassificacao .wrapperForm .container .form .formButtons.center {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

#pageClassificacao .wrapperForm .container .form .formButtons:not(.center) button {
  margin-right: 2rem;
}

/* --- Mobile --- */

@media screen and (max-width: 878px) {
  
  #pageClassificacao .wrapperDashboard .container { }

  #pageClassificacao .wrapperForm .container {
    padding: 0;
  }

  #pageClassificacao .wrapperForm .container .form .MuiStepContent-root {
    padding-left: 20px;
  }

  #pageClassificacao .wrapperForm .container .form .formElements {
    display: grid;
    grid-template-columns: 1fr;
  }

  #pageClassificacao .wrapperForm .container .form .formButtons {
    display: grid;
    grid-template-columns: 1fr;
  }

  #pageClassificacao .wrapperForm .container .form .formButtons button {
    margin-right: 0;
    margin-top: 1rem;
  }

}