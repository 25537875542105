#pageSobre .container {
  padding: 5rem 2rem;
}

#pageSobre .container h1,
#pageSobre .container h2,
#pageSobre .container h3 {
  margin-top: 0;
  color: var(--primary-color-blue);
}

#pageSobre .info p {
  text-align: justify;
}

/* --- Resumo --- */

#pageSobre .resumo {
  background-color: #f3f3f3;
}

#pageSobre .resumo .container h1 {
  font-size: var(--h3-font-size);
}

#pageSobre .resumo .container .info p:last-of-type {
  margin-bottom: 0;
}

#pageSobre .resumo .container .MuiPaper-root,
#pageSobre .resumo .container .MuiAccordionSummary-root,
#pageSobre .resumo .container .MuiAccordionDetails-root {
  padding: 0;
  margin: initial;
  color: inherit;
  background: initial;
  box-shadow: initial;
  border: initial;
}

#pageSobre .resumo .container .MuiAccordionSummary-root {
  transition: all 0.5s;
}

#pageSobre .resumo .container .MuiAccordionSummary-root.Mui-expanded {
  opacity: 0;
  height: 0;
  min-height: 0;
  overflow: hidden;
}

#pageSobre .resumo .container .MuiAccordionSummary-root {
  color: var(--primary-color-blue);
  text-decoration: underline;
}

/* --- Equipe --- */

#pageSobre .equipe .container:first-of-type {
  padding-bottom: 0;
}

#pageSobre .equipe .container h2 {
  font-size: var(--h3-font-size);
  font-weight: 700;
}

#pageSobre .equipe .container h4 {
  margin-top: 0;
  color: var(--primary-color-blue);
}

#pageSobre .equipe .container .listMembros {
  display: flex;
  flex: 1;
  flex-flow: wrap;
}

#pageSobre .equipe .container .membro.sem-foto {
  padding: 4rem 2rem;
  background-color: #f5f5f5;
  border: 1px solid #eee;
}

#pageSobre .equipe .container .membro {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 1rem;
  width: calc(33.3% - 2rem);
  padding: 2rem;
  text-decoration: none;

  border-top: 4px solid transparent;
  border-radius: 5px;
  transition: all 300ms;
}

#pageSobre .equipe .container .membro figure {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1rem;
  width: 12rem;
  height: 12rem;
  background-color: #eee;
  border-radius: 50%;
  overflow: hidden;
}
#pageSobre .equipe .container .membro figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#pageSobre .equipe .container .membro p {
  margin: 0;
  text-align: center;
}

#pageSobre .equipe .container .membro p.nome {
  font-weight: 700;
}

#pageSobre .equipe .container .membro a {
  color: inherit;
  text-decoration: none;
}

#pageSobre .equipe .container .membro:hover a {
  color: var(--primary-color-blue);
  text-decoration: none;
}

#pageSobre .equipe .container .membro p.bio {
  opacity: 0.5;
}

/* --- Convite --- */

#pageSobre .convite {
  /* background-color: #f3f3f3; */
}

/* --- Mobile --- */

@media screen and (max-width: 768px) {
  #pageSobre .resumo .container .info {
    column-count: initial;
    column-gap: initial;
  }

  #pageSobre .equipe .container .membro {
    width: 100%;
    padding: 1.5rem;
  }
}