#navigation {
  background: #7c0f17;
  background: linear-gradient(90deg, rgba(141, 21, 32, 1) 0%, rgba(90, 50, 79, 1) 100%);
  transition: all 300ms;
}

#navigation #closeIconButton{
  display: none;
  position: fixed;
  top: 1rem;
  right: 1rem;
  color: #fff;
}

#navigation ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 0 auto;
}

#navigation ul li {
  position: relative;
}

#navigation ul li a {
  display: flex;
  padding: 20px 1rem;
  color: #fff;
  text-decoration: none;
  border-style: solid;
  border-color: transparent;
  border-width: 4px 0;
  transition: all 300ms;
}

#navigation ul li:hover > a:not(.active) {
  text-shadow: 0 0 1px #fff;
  transform: translateY(-2px);
}

#navigation ul:not(ul.submenu) > li > a.active {
  font-weight: bold;
  border-bottom-color: var(--primary-color-yellow);
}

/* Link Icon */

#navigation ul li a svg {
  opacity: 0.5;
  margin-bottom: -5px;
  margin-right: -10px;
  margin-left: 5px;
}

/* Submenu */

#navigation ul li.hasSubmenu:hover a {
  border-bottom-color: transparent !important;
}

#navigation ul li.hasSubmenu:hover > a {
  pointer-events: none;
}

#navigation ul.submenu {
  opacity: 0;
  flex-direction: column;
  position: absolute;
  z-index: 11;
  top: calc(100% - 4px);
  padding: 0;
  min-width: 200px;
  height: 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-1rem);
  transition: all 500ms;
  overflow: hidden;
}

#navigation ul.submenu::before {
  content: "";
  position: absolute;
  top: -7px;
  left: calc(50% - 3px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #fff transparent;
}

#navigation ul li.hasSubmenu:active ul.submenu,
#navigation ul li.hasSubmenu:hover ul.submenu {
  opacity: 1;
  height: fit-content;
  transform: translateY(0);
  overflow: initial;
}

#navigation ul.submenu li {
  border-bottom: 1px solid #eee;
}
#navigation ul.submenu li:last-of-type {
  border-bottom: none;
}

#navigation ul.submenu li a {
  color: var(--color-red-dark);
}  

#navigation ul.submenu li a:hover:not(.active) {
  text-shadow: 0 0 1px var(--color-red-dark);
  transform: translateY(-2px);
}  
#navigation ul.submenu li a.active {
  font-weight: bold;
}


/* --- Mobile --- */

  @media (min-width: 900px) {
    #navigation ul li a {
      padding: 20px 1.6rem;
    }
  }

  @media (max-width: 1000px) {
    #navigation ul li.hasSubmenu ul.submenu {
      opacity: 1;
      height: fit-content;
      transform: translateY(0);
      overflow: initial;
    }

    #navigation {
      display: none;
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: 10;
      width: 100vw;
      height: 100vh;
      overflow: auto;
    }

    #navigation #closeIconButton {
      display: inline-block;
    }

    #navigation ul {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      padding: 2rem 0;
      min-height: 100%;
    }
    
    #navigation ul li a {
      padding: 0.2rem 3rem;
      font-size: var(--p-font-size);
    }

    #navigation ul li:hover a:not(.active) {
      transform: none;
    }
    
    #navigation ul li a.active {
      font-weight: bold;
      border: 0;
      border-color: transparent;
      border-left: 5px solid var(--primary-color-yellow);
      pointer-events: none;
    }

    body.navigation-open #navigation {
      display: block;
    }

    /* Submenu */

    #navigation ul.submenu {
      display: block;
      flex-direction: column;
      position: initial;
      padding: 0;
      height: initial;
      background: initial;
      border-radius: 5px;
      box-shadow: none;
    }

    #navigation ul.submenu::before {
      display: none;
    }
    
    #navigation ul.submenu li {
      border: none;
    }
    
    #navigation ul.submenu li a {
      padding-top: 5px;
      padding-bottom: 5px;
      text-indent: 2rem;
      color: #fff;
    }
  }