#pageArtigosDetalhes .container {
  padding: 5rem 2rem;
}

#pageArtigosDetalhes .container.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
}

/* --- Header --- */

#pageArtigosDetalhes .wrapperHeader .container {
  padding-bottom: 1rem;
}

#pageArtigosDetalhes .wrapperHeader .container h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: var(--h3-font-size);
  color: var(--primary-color-blue);
}

#pageArtigosDetalhes .wrapperHeader .container p {
  margin-top: 15px;
  color: var(--color-black-lighter);
}

/* --- artigo --- */

#pageArtigosDetalhes .wrapperArtigo .container {
  padding-top: 0;
}

#pageArtigosDetalhes .wrapperArtigo .container .artigo {
  padding-bottom: 4rem;
}

#pageArtigosDetalhes .wrapperArtigo .container .artigo h2 {
  font-size: var(--h5-font-size);
  color: var(--primary-color-blue);
}

#pageArtigosDetalhes .wrapperArtigo .container .artigo .MuiCircularProgress-root {
  position: absolute;
  z-index: 0;
  top: 35%;
  left: 50%;
}

#pageArtigosDetalhes .wrapperArtigo .container .artigo a.button {
  /* button pdf button link */
  display: inline-block;
  padding: 0.75rem 2rem;
  margin: 1rem 0;
  font-weight: 500;
  color: #fff;  
  text-decoration: none;
  border: 0;
  border-radius: 0.2rem;
  background-color: var(--primary-color-blue);
  transition: all 300ms;
}

#pageArtigosDetalhes .wrapperArtigo .container .artigo a.button:hover {
  background-color: var(--color-blue-dark);
}


/* --- Mobile --- */

@media screen and (max-width: 768px) {
  
  #pageArtigosDetalhes .wrapperArtigo .container { }

}