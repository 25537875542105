#pageVisualizacoesLista .container {
  padding: 5rem 2rem;
}

#pageVisualizacoesLista .container h1,
#pageVisualizacoesLista .container h2,
#pageVisualizacoesLista .container h3 {
  margin-top: 0;
  color: var(--primary-color-blue);
}

/* --- Info --- */

#pageVisualizacoesLista .wrapperInfo .container {
  padding-bottom: 0;
}

#pageVisualizacoesLista .wrapperInfo .container h1 {
  font-size: var(--h3-font-size);
}

#pageVisualizacoesLista .wrapperInfo .container .info p:last-of-type {
  margin-bottom: 0;
}

/* --- Lista --- */

#pageVisualizacoesLista .wrapperList .container {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#pageVisualizacoesLista .wrapperList .container h2 {
  font-size: var(--h5-font-size);
}

#pageVisualizacoesLista .wrapperList .container .listItem {
  width: 25%;
}

/* --- Quality --- */

#pageVisualizacoesLista .wrapperQuality {
  background-image: 
    linear-gradient(155deg, #a81824 0%, var(--primary-color-blue) 100%),
    url(images/visualizacoes_quality.png);
  background-size: cover;
  background-blend-mode: multiply;
  background-position: center top;
}

#pageVisualizacoesLista .wrapperQuality .container {
  
}
#pageVisualizacoesLista .wrapperQuality .container h2 {
  max-width: 300px;
  font-size: var(--h1-font-size);
  color: #fff;
}

#pageVisualizacoesLista .wrapperQuality .container p {
  max-width: 300px;
  margin-bottom: 2rem;
  color: #fff;
}


/* --- Mobile --- */

@media screen and (max-width: 768px) {
  #pageVisualizacoesLista .container {  }

  /* --- Lista --- */
  
  #pageVisualizacoesLista .wrapperList .container {
    gap: 1rem;
  }
}