#pageVisualizacoesDetalhes .container {
  padding: 5rem 2rem;
}

/* --- Info --- */

#pageVisualizacoesDetalhes .wrapperInfo {
  background-color: #f3f3f3;
}

#pageVisualizacoesDetalhes .wrapperInfo .container {
  padding-bottom: 8rem;
}

#pageVisualizacoesDetalhes .wrapperInfo .container a {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: var(--p-font-size);
  color: var(--primary-color-blue);
  text-decoration: none;
}

#pageVisualizacoesDetalhes .wrapperInfo .container h1 {
  margin-top: 0;
  font-size: var(--h3-font-size);
  color: var(--primary-color-blue);
}

#pageVisualizacoesDetalhes .wrapperInfo .container .info p:last-of-type {
  margin-bottom: 0;
}

/* --- Visualization --- */

#pageVisualizacoesDetalhes .wrapperVisualization .container {
  padding-top: 0;
  position: relative;
  top: -6rem;
}

#pageVisualizacoesDetalhes .wrapperVisualization .container .visualization {
  position: relative;
  padding: 1rem;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #fff;
}

#pageVisualizacoesDetalhes .wrapperVisualization .container .visualization .MuiCircularProgress-root {
  position: absolute;
  z-index: 0;
  top: 35%;
  left: 50%;
}


#pageVisualizacoesDetalhes .wrapperVisualization .container .visualization iframe {
  position: relative;
  z-index: 1;
}

#pageVisualizacoesDetalhes .description{
  text-align: justify;
}

/* --- Mobile --- */

@media screen and (max-width: 768px) {
  
  #pageVisualizacoesDetalhes .wrapperVisualization .container { }

}