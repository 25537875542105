.container {
  display: flow-root;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 0 2rem;
}

body.no-scroll {
  overflow: hidden;
}

// TODO: query for large screens padding
