.MuiModal {
  padding: 0;
}

/* Content */

.MuiModalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0,0,0,0.5);
  border-radius: 5px;
  outline: none;
}

.MuiModalContentIframe header {
  position: absolute;
  top: 0;
  right: 18px;
  left: 0;
  border-radius: 5px 0 0 0;
}

/* Header */

.MuiModalContentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  height: 72px;
  color: var(--primary-color-blue);
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
  border-radius: 5px 5px 0 0;
}

.MuiModalContentHeader h1 {
  font-size: var(--h4-font-size) !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.MuiModalContentHeader button {
  margin-left: 1rem;
}

/* Mobile */

@media screen and (max-width: 1043px) {
  .MuiModalContentHeader {
    height: 50px;
  }

  .MuiModalContentHeader h1 {
    font-size: var(--h6-font-size) !important;
  }
}